<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="telemarketingProducts.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('TelemarketingScripts')"
    @cancel="onCancel('TelemarketingScripts')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TelemarketingScriptCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'TelemarketingScripts',
      pageHeader: 'Создание скрипта телемаркетинга',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Скрипты телемаркетинга',
          route: { name: 'TelemarketingScripts' },
        },
        {
          text: 'Создание скрипта телемаркетинга',
        },
      ],
      initialForm: {
        productId: null,
        script: null,
        scriptText: null,
        messageHeader: null,
        messageText: null,
        file: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      telemarketingProducts: (state) => state.common.telemarketingProducts,
    }),

    fields() {
      return [
        {
          groups: [
            {
              form: [
                {
                  type: 'select',
                  key: 'productId',
                  label: 'Продукт',
                  list: this.telemarketingProducts.data,
                },
                {
                  type: 'text',
                  key: 'script',
                  label: 'Скрипт',
                },
                {
                  type: 'textarea',
                  key: 'scriptText',
                  label: 'Текст скрипта',
                },
              ],
            },
            {
              groupTitle: 'Коммерческое предложение для отправки на Email',
              form: [
                {
                  type: 'file',
                  key: 'file',
                  label: 'Файл коммерческого предложения',
                },
                {
                  type: 'text',
                  key: 'messageHeader',
                  label: 'Заголовок письма',
                },
                {
                  type: 'textarea',
                  key: 'messageText',
                  label: 'Текст письма',
                },
              ],
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'TelemarketingProducts' });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onSave(route) {
      const mandatoryFields = [
        { key: 'productId', label: 'Продукт' },
        { key: 'script', label: 'Скрипт' },
        { key: 'scriptText', label: 'Текст скрипта' },
        { key: 'messageHeader', label: 'Заголовок письма' },
        { key: 'messageText', label: 'Текст письма' },
      ];

      const header = this.validateForm(this.form, mandatoryFields);
      if (header) {
        this.$notify({
          header,
          type: 'error',
          timer: 5000,
        });
        return;
      }

      this.isLoadingPage = true;

      this.createItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({ name: route });
          }
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
};
</script>
